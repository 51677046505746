import revive_payload_client_4sVQNw7RlN from "/opt/atlassian/pipelines/agent/build/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/atlassian/pipelines/agent/build/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/atlassian/pipelines/agent/build/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/atlassian/pipelines/agent/build/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/atlassian/pipelines/agent/build/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/atlassian/pipelines/agent/build/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/atlassian/pipelines/agent/build/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/opt/atlassian/pipelines/agent/build/frontend/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_UYiXMU8ZyN from "/opt/atlassian/pipelines/agent/build/frontend/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import sentry_client_VdOeuw25Mb from "/opt/atlassian/pipelines/agent/build/frontend/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/atlassian/pipelines/agent/build/frontend/.nuxt/sentry-client-config.mjs";
import plugin_gab9g3XuEx from "/opt/atlassian/pipelines/agent/build/frontend/node_modules/nuxt-aos/dist/runtime/plugin.js";
import loading_client_uMbZhYCS7M from "/opt/atlassian/pipelines/agent/build/frontend/plugins/loading.client.ts";
import preview_6oGpsaen2C from "/opt/atlassian/pipelines/agent/build/frontend/plugins/preview.ts";
import siteHeader_client_Fmp0Hy1tta from "/opt/atlassian/pipelines/agent/build/frontend/plugins/siteHeader.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_client_UYiXMU8ZyN,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_gab9g3XuEx,
  loading_client_uMbZhYCS7M,
  preview_6oGpsaen2C,
  siteHeader_client_Fmp0Hy1tta
]